import { Injectable } from '@angular/core';

import { QueryEntity, combineQueries } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';

import * as moment from 'moment';
import { NgxPermissionsObject, NgxPermissionsService } from 'ngx-permissions';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { Permissions } from '@core/auth/permissions';
import { environment } from '@environment/environment';

import { Lookup } from '@shared/state/lookup/lookup';
import { LookupService } from '@shared/state/lookup/lookup.service';
import { Lookups } from '@shared/state/lookup/lookups';

import { Class } from './class';
import { ClassesState, ClassesStore } from './classes.store';

@Injectable({
    providedIn: 'root',
})
export class ClassesQuery extends QueryEntity<ClassesState, Class> {
    constructor(
        protected store: ClassesStore,
        private routerQuery: RouterQuery,
        private authService: AuthService,
        private lookupService: LookupService,
        private permissionService: NgxPermissionsService
    ) {
        super(store);
    }
    organizations$: Observable<Array<Lookup>> = this.lookupService.selectLookup(
        Lookups.ContentExchangeEnabledOrganizations
    );

    selectActiveClass$: Observable<Class> = combineQueries([
        this.routerQuery.selectParams(),
        this.selectAll(),
    ]).pipe(
        map((combine) => {
            const classItem = combine[1].find((x) => x.id === combine[0]['id']);
            if (classItem) {
                return {
                    ...classItem,
                    startDateTime: classItem?.startDateTime
                        ? moment(classItem.startDateTime)
                        : null,
                };
            }
            return classItem;
        })
    );

    selectIsEditMode$: Observable<boolean> = this.routerQuery
        .selectParams('id')
        .pipe(map((value) => value !== undefined));

    selectIsContentExchangeEnabled$: Observable<boolean> = combineQueries([
        this.selectActiveClass$,
        this.permissionService.permissions$,
    ]).pipe(
        filter(Boolean),
        map((combine: [Class, NgxPermissionsObject]) => {
            const classItem = combine[0];
            const tenantId = this.authService.tenantId;
            const hasSharingOutsideOrganizationPermission =
                combine[1][Permissions.Classes.SharingOutsideOrganization];

            return (
                hasSharingOutsideOrganizationPermission &&
                classItem &&
                classItem.organizationId &&
                classItem.organizationId === tenantId &&
                classItem.organizationContentExchangeEnabled
            );
        })
    );

    selectIsClassFromAnotherOrg$: Observable<boolean> = combineQueries([
        this.selectActiveClass$,
    ]).pipe(
        map((classItem) => {
            const tenantId = this.authService.tenantId;
            return (
                classItem[0] &&
                classItem[0].organizationId &&
                classItem[0].organizationId !== tenantId
            );
        })
    );

    selectIsCurrentOrgContentExchangeEnabled$: Observable<boolean> =
        combineQueries([
            this.organizations$,
            this.permissionService.permissions$,
        ]).pipe(
            map((combine) => {
                const organizations = combine[0];
                const hasSharingOutsideOrganizationPermission =
                    combine[1]['Classes.SharingOutsideOrganization'];
                const tenantId = this.authService.tenantId;
                return (
                    hasSharingOutsideOrganizationPermission &&
                    organizations.filter((organization) => {
                        return organization.id === tenantId;
                    }).length != 0
                );
            })
        );

    selectOrganizationsWithoutLoggedOne$: Observable<Lookup[]> =
        this.organizations$.pipe(
            map((organizations: Lookup[]) => {
                const tenantId = this.authService.tenantId;
                return organizations.filter(
                    (organization: Lookup) => organization.id != tenantId
                );
            })
        );

    getIsEditMode(): boolean {
        return this.routerQuery.getParams('id') !== undefined;
    }

    getEditingId(): string {
        return this.routerQuery.getParams('id');
    }

    getActiveClass(): Class {
        return this.getEntity(this.routerQuery.getParams('id'));
    }

    selectIsZoomEmbeddedEnabled(): boolean {
        const isZoomEmbeddedEnabled = true;
        // environment.namespace === 'dev' || environment.namespace === 'demo';
        return isZoomEmbeddedEnabled;
    }
}
