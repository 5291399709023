import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { Router } from '@angular/router';

import { ID } from '@datorama/akita';

import { Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

import { PublicEvent } from '@shared/public-cards/public-event-card/state/public-event';

import { UserClass } from '@public/classes/class-state/class';

import { PublicClassCardService } from './public-class-card.service';

@Component({
    selector: 'vsc-public-class-card',
    templateUrl: './public-class-card.component.html',
    styleUrls: ['./public-class-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublicClassCardComponent implements OnInit {
    constructor(
        private publicClassCardService: PublicClassCardService,
        private router: Router
    ) {}

    @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
    @Input() class$: Observable<UserClass>;

    @Output() private addClassToFavorite: EventEmitter<ID> = new EventEmitter();

    @Output() private removeClassFromFavorite: EventEmitter<ID> =
        new EventEmitter();

    @Output() private navigate: EventEmitter<string> = new EventEmitter();

    display: string;
    isVerticalOrientation: boolean;

    isClassSessionReady$: Observable<boolean>;
    publicEvent$: Observable<PublicEvent>;

    ngOnInit(): void {
        this.isClassSessionReady$ = this.class$.pipe(
            filter((userClass: UserClass) => !!userClass?.classSession),
            take(1),
            switchMap((userClass: UserClass) =>
                this.publicClassCardService.selectSessionReady(
                    userClass.classSession
                )
            )
        );

        this.publicEvent$ = this.class$.pipe(
            map(
                (userClass: UserClass) =>
                    userClass?.classSession &&
                    ({
                        title: this.getClassTitle(userClass),
                        chipTitle: userClass.classCategory?.name,
                        startDateTime: userClass.classSession.startDateTime,
                        duration: userClass.classSession.duration,
                        organizer: userClass.facilitator,
                        imageUrl: userClass.imageUrl,
                        isLiveStream: userClass.isLiveStream,
                        groupLogoUrl: userClass.brandedLogoUrl,
                        isReady$: this.isClassSessionReady$,
                        isClassRecorded: userClass.classSession.isClassRecorded,
                        hasAnyMaterialsAdded:
                            userClass.classSession.hasAnyMaterialsAdded,
                        hasAnyRecordingsAdded:
                            userClass.classSession.hasAnyRecordingsAdded,
                        FacilitatorNameForSession: userClass.facilitator,
                    } as PublicEvent)
            )
        );
    }

    addToFavorite(event: MouseEvent, classId: ID): void {
        event.stopPropagation();
        this.addClassToFavorite.emit(classId);
    }

    removeFromFavorite(event: MouseEvent, classId: ID): void {
        event.stopPropagation();
        this.removeClassFromFavorite.emit(classId);
    }

    goToClassSessionDetailsPage(classId: ID, classSessionId: ID): void {
        const backUrl = this.router.url;
        this.router.navigate([
            `/classes/${classId}/sessions/${classSessionId}`,
        ]);
        this.navigate.emit(backUrl);
    }

    getClassTitle(userClass: UserClass): string {
        if (!userClass) {
            return '';
        }
        return userClass.name == userClass.classSession?.name
            ? userClass.name
            : userClass.name + ': ' + userClass.classSession?.name;
    }
}
