import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ID } from '@datorama/akita';

import { Observable } from 'rxjs';

import { environment } from '@environment/environment';

import {
    ClassCreationURL,
    ClassSession,
} from '@public/classes/class-session-state/class-session';

import { ClassAttendance } from '../state/class-attendance';
import { Classroom } from '../state/classroom';

@Injectable({ providedIn: 'root' })
export class ClassroomDataService {
    constructor(private httpClient: HttpClient) {}

    getClassroomSignature(classSessionId: string): Observable<Classroom> {
        return this.httpClient.get<Classroom>(
            `${environment.apiUrl}/ag/classes/sessions/${classSessionId}/signature`
        );
    }

    getUserInfo(classSessionId: string): Observable<Classroom> {
        return this.httpClient.get<Classroom>(
            `${environment.apiUrl}/vsc/classes/sessions/${classSessionId}/join`
        );
    }

    getById(classSessionId: ID): Observable<ClassSession> {
        return this.httpClient.get<ClassSession>(
            `${environment.apiUrl}/vsc/classes/sessions/${classSessionId}`
        );
    }

    recordClassAttendance(
        classAttendance: ClassAttendance
    ): Observable<ClassAttendance> {
        return this.httpClient.post<ClassAttendance>(
            `${environment.apiUrl}/vsc/classes/session/record-attendance`,
            classAttendance
        );
    }

    createMeetingUrl(classId: string): Observable<ClassCreationURL> {
        return this.httpClient.post<ClassCreationURL>(
            `${environment.apiUrl}/ag/classes/${classId}/createClasss`,
            {} // Assuming your API does not need a body; add a body object here if necessary
        );
    }
}
