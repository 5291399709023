import { HttpClient } from '@angular/common/http';
import {
    Component,
    Input,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { Observable, observable, take } from 'rxjs';

import { ClassesQuery } from '@app/admin/admin/classes/class-state/classes.query';
import {
    ClassCreationURL,
    ClassSession,
} from '@app/public/public/classes/class-session-state/class-session';
import { ClassroomQuery } from '@app/public/public/classroom/state/classroom.query';
import { AuthService } from '@core/auth/auth.service';

import { SnackBarService } from '@shared/snack-bar/snack-bar.service';

import { ClassroomService } from '@public/classroom/services/classroom.service';

@Component({
    selector: 'vsc-join-class',
    templateUrl: './join-class.component.html',
    styleUrls: ['./join-class.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JoinClassComponent implements OnInit {
    @Input() classSessionId: string;
    @Input() text: string = 'Join program';
    @Input() isLiveStream: boolean;
    @Input() isZoomEmbedded: boolean;
    @Input() classId: string;
    isZoomCreated: boolean;
    createClassId: string = null;
    isLoading: Observable<true>;

    constructor(
        private router: Router,
        private classroomService: ClassroomService,
        private authService: AuthService,
        private cdn: ChangeDetectorRef,
        private snackBar: SnackBarService,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private classQuery: ClassesQuery,
        private classroomQuery: ClassroomService
    ) {}
    ngOnInit(): void {
        this.classroomQuery
            .getById(this.classSessionId)
            .pipe(take(1))
            .subscribe({
                next: (classSession: ClassSession) => {
                    this.isZoomCreated = classSession.isZoomEmbedded;
                },
            });
    }

    navigateToClassroom(classSessionId: string, classId: string = null): void {
        if (this.isLiveStream && classId != null) {
            this.router.navigate([
                `/classes/${classId}/sessions/${classSessionId}`,
            ]);
            this.classroomService
                .recordClassAttendance(classSessionId)
                .pipe(take(1))
                .subscribe();
        } else if (this.isZoomCreated) {
            this.router.navigate([`/classroom/${classSessionId}/join`]);
        } else {
            this.classroomService
                .createMeeting(classId)
                .pipe(take(1))
                .subscribe({
                    next: (classCreationUrl: ClassCreationURL) => {
                        if (
                            classCreationUrl.roomUrl &&
                            classCreationUrl.hostRoomUrl
                        ) {
                            this.router.navigate([
                                `/whereby-classroom/${classSessionId}/join`,
                            ]);
                        } else {
                            this.snackBar.open(
                                'Something Went Wrong. Please Try Again.'
                            );
                        }
                    },
                });
        }
    }
}
