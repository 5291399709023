import { ID } from '@datorama/akita';

export class ClassAttendance {
    userId: ID;
    classSessionId: ID;
    roomUrl;

    constructor(userId, classSessionId) {
        this.userId = userId;
        this.classSessionId = classSessionId;
    }
}
