import { Injectable } from '@angular/core';

import { ID, QueryEntity } from '@datorama/akita';
import { RouterQuery } from '@datorama/akita-ng-router-store';
import { TranslocoService } from '@ngneat/transloco';

import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

import { AuthService } from '@app/core/auth/auth.service';
import { environment } from '@environment/environment';

import { Constants } from '@shared/constants';

import { ClassSession } from '@public/classes/class-session-state/class-session';

import { UserRole } from '../user-role.enum';
import { ClassAttendance } from './class-attendance';
import { ClassroomState, ClassroomStore } from './classroom.store';

@Injectable({
    providedIn: 'root',
})
export class ClassroomQuery extends QueryEntity<ClassroomState, ClassSession> {
    constructor(
        protected store: ClassroomStore,
        private routerQuery: RouterQuery,
        private translocoService: TranslocoService,
        private deviceDetectionService: DeviceDetectorService,
        private authService: AuthService
    ) {
        super(store);
    }

    selectBackUrl$: Observable<string> = this.select(
        (classroomState: ClassroomState) => classroomState.backUrl
    );

    getActiveSession(id: ID): ClassSession {
        return this.getEntity(id);
    }

    getBackUrl(): string {
        return this.getValue()?.backUrl ?? Constants.CLASSROOM_DEFAULT_BACK_URL;
    }

    selectIsMobileDevice(): boolean {
        return (
            this.deviceDetectionService.isMobile() ||
            this.deviceDetectionService.isTablet()
        );
    }

    selectZoomUrlProtocol(): string {
        return this.selectIsMobileDevice()
            ? Constants.ZOOM_MOBILE_NAVIGATION_URL
            : Constants.ZOOM_DESKTOP_NAVIGATION_URL;
    }

    getLeaveIndicator(): number {
        return this.getValue().leaveIndicator;
    }

    getSessionClassId(): string {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        return classSession?.classId;
    }

    getMeetingSignature(): string {
        const classroomInfo = this.routerQuery.getData('classroom');
        return classroomInfo?.signature;
    }

    selectIsZoomEmbedded(): boolean {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        return classSession?.isZoomEmbedded ?? false; // Added a default value in case it's undefined
    }

    selectClassSessionName(): string {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        return classSession?.name ?? ''; // Added a default value
    }

    selectClassroomNumber(): string {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        return classSession?.meetingNumber ?? ''; // Added a default value
    }

    selectClassroomPassword(): string {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        return classSession?.meetingPassword ?? ''; // Added a default value
    }

    selectZoomUserRole(): number {
        const classSession = this.getEntity(this.routerQuery.getParams('id'));
        const userRole =
            classSession?.isHostOrCoHost || classSession?.isZoomAlternativeHost
                ? UserRole.Host
                : UserRole.Guest;

        return userRole;
    }

    selectUsername(): string {
        return encodeURIComponent(
            `${
                this.routerQuery.getData('classroom')?.firstName
            } ${this.routerQuery
                .getData('classroom')
                ?.lastName.substring(0, 1)}.`
        );
    }

    getLanguageCode(): string {
        return this.mapLanguageCode(this.translocoService.getActiveLang());
    }

    getOriginUrl(): string {
        const zoomHtmlPath =
            environment.namespace === 'prod'
                ? Constants.PROD_ZOOM_HTML_PATH
                : Constants.DEV_ZOOM_HTML_PATH;
        return window.location.origin + zoomHtmlPath;
    }

    selectClassAttendanceRecord(classSessionId: ID): ClassAttendance {
        return new ClassAttendance(this.authService.userId, classSessionId);
    }

    mapLanguageCode(language: string): string {
        switch (language) {
            case 'en':
            case 'ko':
            default: {
                return 'en-US';
            }
            case 'es': {
                return 'es-ES';
            }
            case 'ru': {
                return 'ru-RU';
            }
            case 'zh': {
                return 'zh-TW';
            }
        }
    }
}
