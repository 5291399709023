import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { OAuthErrorEvent, OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import * as moment from 'moment';
import {
    BehaviorSubject,
    combineLatest,
    Observable,
    ReplaySubject,
} from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { clearEmbedTokenPersistState } from '@app/admin/admin/reports/state/embed-token-store.config';
import { environment } from '@environment/environment';

import { BuiltInRoleType } from '@shared/built-in-role-type';
import { Constants } from '@shared/constants';

import { clearReportsPersistState } from '@admin/reports/state/reports-store.config';
import { UserStatus } from '@admin/users/user.enum';

import { OrganizationSetting } from './organization-settings.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public isAuthenticated$: Observable<boolean>;
    public isDoneLoading$: Observable<boolean>;

    /**
     * Publishes `true` if and only if (a) all the asynchronous initial
     * login calls have completed or errored, and (b) the user ended up
     * being authenticated.
     *
     * In essence, it combines:
     *
     * - the latest known state of whether the user is authorized
     * - whether the ajax calls for initial log in have all been done
     */
    public canActivateProtectedRoutes$: Observable<boolean>;

    private claims$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    private claims: Array<any> = [];
    private currentTenant: string = null;
    private isDoneLoadingSubject$: ReplaySubject<boolean> =
        new ReplaySubject<boolean>();
    private isAuthenticatedSubject$: BehaviorSubject<boolean> =
        new BehaviorSubject<boolean>(false);

    constructor(
        private oauthService: OAuthService,
        private router: Router,
        private httpClient: HttpClient
    ) {
        this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
        this.isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
        this.canActivateProtectedRoutes$ = combineLatest([
            this.isAuthenticated$,
            this.isDoneLoading$,
        ]).pipe(map((values: any) => values.every((b) => b)));

        if (!environment.production) {
            this.oauthService.events.subscribe((event) => {
                if (event instanceof OAuthErrorEvent) {
                    console.error(event);
                } else if (event?.type !== 'session_unchanged') {
                    console.warn(event);
                }
            });
        }

        // This is tricky, as it might cause race conditions (where access_token is set in another
        // tab before everything is said and done there.
        window.addEventListener('storage', (event) => {
            // The `key` is `null` if the event was caused by `.clear()`
            if (event.key !== 'access_token' && event.key !== null) {
                return;
            }

            console.warn(
                'Noticed changes to access_token (most likely from another tab), updating isAuthenticated'
            );
            this.isAuthenticatedSubject$.next(
                this.oauthService.hasValidAccessToken()
            );

            if (!this.oauthService.hasValidAccessToken()) {
                this.navigateToLoginPage();
            }
        });

        this.oauthService.events.subscribe((_) => {
            this.isAuthenticatedSubject$.next(
                this.oauthService.hasValidAccessToken()
            );
        });

        this.oauthService.setupAutomaticSilentRefresh();
    }

    public runInitialLoginSequence(): Promise<void> {
        if (location.hash) {
            console.log('Encountered hash fragment, plotting as table...');
            console.table(
                location.hash
                    .substr(1)
                    .split('&')
                    .map((kvp) => kvp.split('='))
            );
        }

        // 0. LOAD CONFIG:
        // First we have to check to see how the IdServer is
        // currently configured:
        return (
            this.oauthService
                .loadDiscoveryDocument()

                // 1. HASH LOGIN:
                // Try to log in via hash fragment after redirect back
                // from IdServer from initImplicitFlow:
                .then(() =>
                    this.oauthService.tryLogin().catch(() => location.reload())
                )
                .then(() => {
                    if (this.oauthService.hasValidAccessToken()) {
                        return Promise.resolve();
                    }
                    // this.oauthService.initCodeFlow();
                    // return Promise.resolve();
                    // 2. SILENT LOGIN:
                    // Try to log in via a refresh because then we can prevent
                    // needing to redirect the user:
                    return this.oauthService.silentRefresh().catch((result) => {
                        // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
                        // Only the ones where it's reasonably sure that sending the
                        // user to the IdServer will help.
                        const errorResponsesRequiringUserInteraction: Array<string> =
                            [
                                'interaction_required',
                                'login_required',
                                'account_selection_required',
                                'consent_required',
                            ];

                        if (
                            (result?.reason?.error ||
                                result?.reason?.params?.error) &&
                            errorResponsesRequiringUserInteraction.indexOf(
                                result.reason.error ||
                                    result.reason.params.error
                            ) >= 0
                        ) {
                            // 3. ASK FOR LOGIN:
                            // At this point we know for sure that we have to ask the
                            // user to log in, so we redirect them to the IdServer to
                            // enter credentials.

                            this.oauthService.initCodeFlow();

                            return Promise.resolve();
                        }

                        // We can't handle the truth, just pass on the problem to the
                        // next handler.
                        return Promise.reject(result);
                    });
                })
                .then(() => {
                    // Check for the strings 'undefined' and 'null' just to be sure. Our current
                    // login(...) should never have this, but in case someone ever calls
                    // initImplicitFlow(undefined | null) this could happen.
                    if (
                        this.oauthService.state &&
                        this.oauthService.state !== 'undefined' &&
                        this.oauthService.state !== 'null'
                    ) {
                        let stateUrl: string = this.oauthService.state;
                        if (stateUrl.startsWith('/') === false) {
                            stateUrl = decodeURIComponent(stateUrl);
                        }
                        this.router.navigateByUrl(stateUrl);
                    }
                    this.currentTenant = this.getAccessTokenClaim('tenant');
                    this.oauthService.customQueryParams = {
                        tenant: this.currentTenant,
                    };
                })
                .then(() => {
                    return this.loadUserProfile();
                })
                .then(() => {
                    this.isDoneLoadingSubject$.next(true);
                })
                .catch((reason) => {
                    console.error(
                        reason +
                            ' Oauth service state' +
                            this.oauthService.state
                    );
                    this.isDoneLoadingSubject$.next(true);
                })
        );
    }

    public loadUserProfile(): Promise<void> {
        return this.oauthService.loadUserProfile().then((claims: any) => {
            this.claims = claims?.info ?? [];
            if (claims) {
                this.claims$.next(this.claims);
                this.setTimeZone();
            }
            return Promise.resolve();
        });
    }

    public loadPermissions(): Observable<Array<string>> {
        return this.httpClient.get<Array<string>>(
            environment.apiUrl + `/vsc/users/${this.userId}/permissions`
        );
    }

    public loadOrganizationSettings(): Observable<Array<OrganizationSetting>> {
        return this.httpClient.get<Array<OrganizationSetting>>(
            environment.apiUrl + `/vsc/applicationsettings`
        );
    }

    public isUserInRole(): Observable<boolean> {
        const builtInRoleTypes: HttpParams = new HttpParams({
            fromObject: {
                roleEnum: [BuiltInRoleType.Moderator.toString()],
            },
        });

        return this.httpClient.get<boolean>(
            `${environment.apiUrl}/vsc/users/${this.userId}/user-roles`,
            { params: builtInRoleTypes }
        );
    }
    public isUserFacilitator(): Observable<boolean> {
        const builtInRoleTypes: HttpParams = new HttpParams({
            fromObject: {
                roleEnum: [BuiltInRoleType.Facilitator.toString()],
            },
        });
        return this.httpClient.get<boolean>(
            `${environment.apiUrl}/vsc/users/${this.userId}/user-roles`,
            { params: builtInRoleTypes }
        );
    }

    public login(targetUrl?: string): void {
        // Note: before version 9.1.0 of the library you needed to
        // call encodeURIComponent on the argument to the method.
        this.oauthService.initCodeFlow(targetUrl || this.router.url);
    }

    public logout(): void {
        moment.fn.timeZone = '';
        clearReportsPersistState();
        clearEmbedTokenPersistState();
        this.oauthService.revokeTokenAndLogout();
    }

    public switchTenant(tenant: string): Promise<OAuthEvent> {
        this.oauthService.customQueryParams = { tenant: tenant };
        this.currentTenant = tenant;
        return this.oauthService.silentRefresh();
    }

    public hasValidToken(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    public get userId(): string {
        return this.claims['sub'];
    }

    public get idpRole(): string {
        return this.claims['role'];
    }

    public get tenantId(): string {
        return this.currentTenant ?? '';
    }

    public tenantName$: Observable<string> = this.claims$.pipe(
        map((value) => {
            return value['tenantName'];
        })
    );

    public get tenantName(): string {
        return this.claims['tenantName'];
    }
    public areUserInterestsSubmitted$: Observable<boolean> = this.claims$.pipe(
        filter(Boolean),
        map((value) => {
            const areUserInterestsSubmitted =
                value['areUserInterestsSubmitted'];

            return (
                areUserInterestsSubmitted && areUserInterestsSubmitted === '1'
            );
        })
    );
    public userType$: Observable<string> = this.claims$.pipe(
        map((value) => {
            return value['userType'];
        })
    );
    public get userType(): string {
        return this.claims['userType'];
    }

    public get userStatus(): UserStatus {
        return +this.claims['userStatus'] as UserStatus;
    }

    public get firstName$(): Observable<string> {
        return this.claims$.pipe(
            map((value) => {
                return value['firstName'];
            })
        );
    }

    public get availableTenants$(): Observable<Array<any>> {
        return this.claims$.pipe(
            map((value) => {
                const tenants = [];

                const raw = value['availableTenants'];
                if (!raw) {
                    return tenants;
                }

                if (Array.isArray(raw)) {
                    raw.forEach((tenant) => {
                        tenants.push(JSON.parse(tenant));
                    });
                } else {
                    tenants.push(JSON.parse(raw));
                }

                return tenants;
            })
        );
    }

    public get timezoneClaim(): string {
        return this.claims['timezone'];
    }

    private get accessToken(): string {
        return this.oauthService.getAccessToken();
    }

    private getAccessTokenClaim(claim: string): string {
        if (this.accessToken != null) {
            const claims = JSON.parse(atob(this.accessToken.split('.')[1]));
            return claims[claim];
        }
        return null;
    }

    private setTimeZone(): void {
        let timeZone = this.timezoneClaim;

        if (timeZone && !moment.tz.zone(timeZone)) {
            console.error(`Invalid time zone: ${timeZone}`);
            timeZone = null;
        }

        timeZone ??= Constants.DEFAULT_TIME_ZONE;

        moment.fn.timeZone = timeZone;
        moment.tz.setDefault(timeZone);
    }

    private navigateToLoginPage(): void {
        window.location.href = '/';
    }
}
