import { Injectable } from '@angular/core';

import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { Class } from './class';

export type ClassesState = EntityState<Class>;

export const initialState: ClassesState = {
    loading: false,
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'classes' })
export class ClassesStore extends EntityStore<ClassesState, Class> {
    constructor() {
        super(initialState);
    }
}
